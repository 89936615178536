// **
// * /!\ POLYFILLS MUST BE AT THE TOP-LEVEL
// **
import '../lib/polyfills'
// **
// * /!\ POLYFILLS MUST BE AT THE TOP-LEVEL
// **

import App from 'next/app'
import Head from 'next/head'
import React from 'react'
import 'react-dates/initialize'
import {Baseline} from '@ambler/andive'
import {Toaster} from '@ambler/andive-next'
import {Auth0Provider} from '@auth0/auth0-react'
import type {FixType} from '@ambler/shared'
import gql from 'graphql-tag'
import Sentry from '../lib/sentry'
import '../lib/react-dates/ambler_datepicker.css'
import '../lib/pdfjs-init'
import {ResponsiveProvider, initializeResponsive} from '../components/stack/responsive'
import {Favicon} from '../components/favicon'
import {CanGoBackProvider} from '../components/stack/router'
import {withAsideLoader} from '../components/aside-loader'
import {NotificationAlertSoundProvider} from '../lib/notification-alert-sound-provider'
import {Updater} from '../components/app-updater'
import {useAuth, auth0Params} from '../components/stack/auth'
import {DateNowProvider} from '../hooks/use-date-now'
import {getUpdateDevicePayload, useForegroundNotifications, useMobileNotifications} from '../lib/firebase-messaging'
import {useSplashAutoHideFallback} from '../lib/mobile/splash-screen'
import {useMutation} from '../hooks/use-mutation'
import {withApollo} from '../lib/with-apollo'
import useAppUrlListeners from '../components/mobile/app-url-listeners'
import type {
  APP_UpdatePushNotificationsDeviceMutationVariables,
  APP_UpdatePushNotificationsDeviceMutation_,
} from './_app.generated'

/**
 * Whenever the app reaches < 360px width, our components
 * have a high chance of overflowing horizontaly. This is fine as
 * we won't design our app for screens less than 360px. Yet we want
 * to avoid breaking in such a way that our user is not able to use
 * anything. For this use-case we enfore the browser to overflow with
 * a scrollbar (instead of *sometime* - understanding Chrome devtools
 * & Samsung Browser - pushing the content of the app in such a way that
 * will block some elements or informations).
 *
 * Why `height: 100%` ?
 * Because: The overflow property only works for block elements with a specified height.
 * TODO: Crawl the CSS standard to find more about overflow property behavior.
 */
initializeResponsive()

const updatePushNotificationsDeviceMutation = gql`
  mutation APP_UpdatePushNotificationsDeviceMutation($data: PushNotificationsUpsertDeviceDataInput!) {
    pushNotificationsUpsertDevice(data: $data)
  }
`

const SentryUserProvider: React.FC = ({children}) => {
  const {user, mfuAcls, mtAcls, isAuthenticated, isEmailConfirmed} = useAuth()
  React.useEffect(() => {
    if (isAuthenticated) {
      Sentry.setUser({
        id: user.id,
        name: user.name,
        email: user.email,
        extra: {
          isEmailConfirmed,
          role: user.role,
          mtAcls: JSON.stringify(mtAcls, null, 2), // if we don't do this, Sentry will not show the nested object
          mfuAcls: JSON.stringify(mfuAcls, null, 2),
        },
      })
    }
  }, [user, mfuAcls, isAuthenticated, mtAcls, isEmailConfirmed])

  return <>{children}</>
}

const MobileAppProvider: React.FC = ({children}) => {
  const [deviceInitialized, setDeviceInitialized] = React.useState(false)
  const {isAuthenticated} = useAuth()

  // ? mobile app splash screen hides automatically in case hideSplashScreen is not called after a certain amount of time
  useSplashAutoHideFallback()
  // ? mobile app auth0callbacks and deeplinks
  useAppUrlListeners()

  const [updateDevice] = useMutation<
    APP_UpdatePushNotificationsDeviceMutation_,
    APP_UpdatePushNotificationsDeviceMutationVariables
  >({
    mutation: updatePushNotificationsDeviceMutation,
  })

  React.useEffect(() => {
    const initDevice = async () => {
      await updateDevice({
        variables: {
          data: await getUpdateDevicePayload(),
        },
      })
    }
    if (isAuthenticated && !deviceInitialized) {
      initDevice()
      setDeviceInitialized(true)
    }
  }, [isAuthenticated, updateDevice, deviceInitialized])

  useMobileNotifications()
  useForegroundNotifications()

  return <>{children}</>
}

class AmbleaApp extends App {
  render() {
    // injected err is a workaround for https://github.com/vercel/next.js/issues/8592
    const {Component, pageProps, err} = this.props as FixType
    return (
      <>
        <Auth0Provider
          domain={auth0Params.domain}
          clientId={auth0Params.client_id}
          redirectUri={auth0Params.redirect_uri}
          scope={auth0Params.scope}
          cacheLocation={auth0Params.cacheLocation}
          cache={auth0Params.cache}
          useRefreshTokens={auth0Params.useRefreshTokens}
        >
          <Head>
            <title>Amblea</title>
            {/* Viewport meta tags should not be used in _document.js's <Head>, moved here
            https://github.com/vercel/next.js/blob/master/errors/no-document-viewport-meta.md */}
            {/* ? fit cover is for ios safe area (notch) */}
            {/* ? user-scalable=no is to disable ios pinching zoom */}
            <meta
              name="viewport"
              content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
          </Head>
          <ResponsiveProvider>
            <CanGoBackProvider>
              <SentryUserProvider>
                <DateNowProvider>
                  <NotificationAlertSoundProvider>
                    <Baseline>
                      <Favicon />
                      <Toaster />
                      <Updater />
                      <MobileAppProvider>
                        <Component {...pageProps} err={err} />
                      </MobileAppProvider>
                    </Baseline>
                  </NotificationAlertSoundProvider>
                </DateNowProvider>
              </SentryUserProvider>
            </CanGoBackProvider>
          </ResponsiveProvider>
        </Auth0Provider>
      </>
    )
  }
}

export default withApollo()(withAsideLoader(AmbleaApp))
