import React from 'react'
import {SplashScreen} from '@capacitor/splash-screen'

export const hideSplashScreen = () => {
  if (!process.env.IS_MOBILE_BUILD) {
    return
  }

  SplashScreen.hide()
}

const AUTO_HIDE_DURATION = 20000
export const useSplashAutoHideFallback = () => {
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      hideSplashScreen()
    }, AUTO_HIDE_DURATION)

    return () => clearTimeout(timeoutId)
  }, [])
}
