// * source: https://nextjs.org/docs/basic-features/supported-browsers-features#custom-polyfills
import 'core-js'
import 'intersection-observer'

if (typeof window !== 'undefined') {
  // Solve the react16 -> 17 migration issue.
  // Makes React focusin/out events works on Firefox <=51 (>=52 there is no need for the polyfill).
  require('focusin').polyfill()
}

export {}
