var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

// Deactive for now.
const _replayOptions = {
  replaysSessionSampleRate: process.env.AMBLER_STAGE === 'prod' ? 0.0 : 1.0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
}

Sentry.init({
  dsn: SENTRY_DSN || 'https://ccbb39c2657e4e7e8bccd8e5e55d0385@o188908.ingest.sentry.io/1286806',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.0001,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: process.env.AMBLER_STAGE || 'local',
  enabled: !process.env.AMBLER_STAGE.startsWith('local'),
  beforeSend(event) {
    // Check if it is an exception and not a GraphQL one, and if so, show the report dialog
    if (event.exception && !event.tags?.operation) {
      Sentry.showReportDialog({eventId: event.event_id})
    }
    return event
  },
  // ..._replayOptions,
})
