import React from 'react'
import {Text} from '@ambler/andive-next'
import {useMobile} from './stack/responsive'

export const Title = <Props extends {children: any}>({children, ...props}: Props) => {
  const isMobile = useMobile()
  return (
    <Text truncate t={isMobile ? 'h3' : 'h2'} {...props}>
      <b>{children}</b>
    </Text>
  )
}
