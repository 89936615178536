import React from 'react'
import {useRouter} from 'next/router'
import useSWR from 'swr'
import ms from 'ms'
import {safeLocalStorage} from '../lib/local-storage'
const fetcher = (...args: Parameters<typeof fetch>) => fetch(...args).then(res => res.json())

import packageInfo from '../package.json'

// https://github.com/substack/semver-compare/blob/master/index.js
function semverCompare(a: string, b: string) {
  const pa = a.split('.')
  const pb = b.split('.')
  for (let i = 0; i < 3; i++) {
    const na = Number(pa[i])
    const nb = Number(pb[i])
    if (na > nb) {
      return 1
    }
    if (nb > na) {
      return -1
    }
    if (!isNaN(na) && isNaN(nb)) {
      return 1
    }
    if (isNaN(na) && !isNaN(nb)) {
      return -1
    }
  }
  return 0
}

export const Updater = (): any => {
  const {data} = useSWR(`/version.json`, fetcher, {refreshInterval: ms('1min')})
  const router = useRouter()

  React.useEffect(() => {
    function onRouteChangeStart(url: Location) {
      const shouldUpdate = data?.version && semverCompare(packageInfo.version, data?.version) === -1
      const lastUpdatedAt = safeLocalStorage.getItem('version.updatedAt')

      if (shouldUpdate && lastUpdatedAt && Date.now() < Number(lastUpdatedAt) + ms('30sec')) {
        console.log(`[version] New version detected, but last update attempt was too soon`)
        return
      }

      if (shouldUpdate) {
        console.log(`[version] New version detected ! (current: ${packageInfo.version}, new: ${data.version})`)
        safeLocalStorage.setItem('version.updatedAt', Date.now().toString())
        window.location = url
        router.events.emit('routeChangeError')
      }
    }

    router.events.on('routeChangeStart', onRouteChangeStart)
    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
    }
  }, [data, router.events])

  return null
}
