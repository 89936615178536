import {useEffect} from 'react'
import {useRouter} from 'next/router'
import type {URLOpenListenerEvent} from '@capacitor/app'
import {App} from '@capacitor/app'
import {Browser} from '@capacitor/browser'
import {Capacitor} from '@capacitor/core'
import {useAuth0} from '@auth0/auth0-react'
import {hideSplashScreen} from '../../lib/mobile/splash-screen'

const useAppUrlListeners = () => {
  const router = useRouter()
  const {handleRedirectCallback} = useAuth0()

  useEffect(() => {
    if (!process.env.IS_MOBILE_BUILD) {
      return
    }

    const handleAuth0Callback = async (search: string, href: string) => {
      if (search.includes('state') && (search.includes('code') || search.includes('error'))) {
        try {
          await handleRedirectCallback(href)
        } catch (_error) {
          // ! I don't understand why we get invalid state error sometimes.
          // https://ambler.sentry.io/issues/4881094655/?project=1286806&query=is:unresolved&statsPeriod=1h&stream_index=0
          // ! but everything seems to work fine if we just ignore the error
          // ? see if it still the case when removing auth0-spa-js
        }
      }
      if (Capacitor.getPlatform() === 'ios') {
        try {
          // ? no op on android (but closes itself anyway)
          await Browser.close()
        } catch (_error) {
          // ? sometimes there is no No active window to close!
        }
      }
    }

    App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
      const url = new URL(event.url)
      const {protocol, pathname, search, href} = url
      if (protocol === 'http:' || protocol === 'https:') {
        // ? deep link
        // eg: https://app.amblea.fr/ts/amblea-invoice/clprip4cu002zqrffy1tkjuwo
        // pathname = /ts/amblea-invoice/clprip4cu002zqrffy1tkjuwo
        router.replace(pathname)
        hideSplashScreen()
      } else {
        if (!href.startsWith(process.env.BUNDLE_ID)) {
          // no op since we only allow fr.amblea.amblea:// schemes
          return
        }

        if (href.includes(process.env.AUTH0_ENDPOINT as unknown as string)) {
          // ? auth0 callback
          // eg: fr.amblea.amblea://auth0.ambler.fr/capacitor/fr.amblea.amblea/callback?code=xxx,state=zzz
          await handleAuth0Callback(search, href)
        } else {
          // ? custom scheme link
          // eg: fr.amblea.amblea://app.amblea.fr/ts/amblea-invoice/clprip4cu002zqrffy1tkjuwo
          // ! chrome URL interface implementation is non standard when it comes to custom scheme.
          const host = new URL(process.env.AMBLER_APP_URL as unknown as string)?.host
          const path = href.split(host).pop()
          router.replace(path)
          hideSplashScreen()
        }
      }
    })
  }, [handleRedirectCallback, router])
}

export default useAppUrlListeners
