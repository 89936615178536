import React, {useState, useEffect, useMemo} from 'react'
import {BackIcon, Button} from '@ambler/andive-next'
import {useRouter} from 'next/router'
import {useProduct} from './product'

type CanGoBackContextType = {
  canGoBack: boolean
}

export const CanGoBackContext = React.createContext<CanGoBackContextType>(null)

/*
 * This provider computes a rough history in order to handle back button logic
 * It uses its own history as landing on a page triggers an
 * routeChangeComplete event making the variable canGoBack always true
 * The provider being placed on _app, its state may not preserved on Fast Refresh
 * so it should be used carefully with a fallback option for development NODE_ENV
 * We also use history as a supplementary condition, as it can only increase
 */
export const CanGoBackProvider: React.FC = ({children}) => {
  const router = useRouter()
  const [routerHistory, setRouterHistory] = useState([router.asPath])

  useEffect(() => {
    const onRouteChangeComplete = (url: string) => {
      setRouterHistory(prevHistory => {
        if (prevHistory.length > 1 && prevHistory[prevHistory.length - 2] === url) {
          return prevHistory.slice(0, prevHistory.length - 1)
        }

        if (prevHistory[prevHistory.length - 1] !== url) {
          return [...prevHistory, url]
        }

        return prevHistory
      })
    }

    router.events.on('routeChangeComplete', onRouteChangeComplete)

    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [router])

  const canGoBack = routerHistory.length > 1 && window.history.length > 1
  const value = useMemo(() => ({canGoBack}), [canGoBack])

  return <CanGoBackContext.Provider value={value}>{children}</CanGoBackContext.Provider>
}

export const BackButton = ({onClick, fallbackTo}: {onClick?: () => void; fallbackTo?: string}) => {
  const router = useRouter()
  const product = useProduct()

  const {canGoBack: hasHistory} = React.useContext(CanGoBackContext)

  const defaultFallBack = product === 'A4H' ? '/es/dashboard' : '/ts/dashboard'

  const onClick_ = () => {
    if (onClick) {
      return onClick()
    }

    if (hasHistory) {
      return router.back()
    }

    return router.replace(fallbackTo ?? defaultFallBack)
  }

  return (
    <Button variant="third" type="button" onClick={onClick_}>
      <Button.Icon icon={<BackIcon />} />
    </Button>
  )
}
