import {palette, Text, Andiv} from '@ambler/andive-next'
import React from 'react'
import {Loader, VSpace} from '@ambler/andive'
import styled from 'styled-components'

const LoaderSpace = styled.div`
  width: 100%;
  height: 33vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
  & > div {
    text-align: center;
  }
`

function LoaderComponent({inline, size, ...props}: {inline?: boolean; size?: number}) {
  return <Loader inline={inline} size={size} {...props} />
}

export const LoaderCard = ({message = 'Chargement en cours ...'}) => {
  return (
    <LoaderSpace>
      <div>
        <Loader inline />
        <VSpace $px={16} />
        <Andiv p="8px" column>
          <Text t="body2" color={palette.amblea.grey[600]}>
            {message}
          </Text>
        </Andiv>
      </div>
    </LoaderSpace>
  )
}

export default LoaderComponent
