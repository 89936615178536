import React from 'react'
import useSound from 'use-sound'

import type {FixType, PushNotificationType} from '@ambler/shared'
import {assertUnreachable} from '@ambler/shared'
import {Button, Tooltip, SoundOnIcon, SoundOffIcon, toastSuccess, palette} from '@ambler/andive-next'
import styled from 'styled-components'
import {useProduct} from '../components/stack/product'
import {getDeviceState} from './firebase-messaging'

const needSound = (value: PushNotificationType) => {
  switch (value) {
    case 'TPTR_PUSH_NEW_CHANGE_REQUEST':
    case 'TPTR_PUSH_NEW_BOOKING_REQUEST':
    case 'TPTR_PUSH_MISSION_BOOKED':
    case 'TPTR_PUSH_HPTL_CANCEL':
    case 'TPTR_PUSH_TPTR_DELEGATE':
    case 'TPTR_PUSH_TPTR_UNDELEGATE':
    case 'TPTR_PUSH_WAIT_CONFIRMATION':
    case 'TPTR_PUSH_NEW_BOOKING_REQUEST_ASAP':
    case 'TPTR_PUSH_NEW_BOOKING_REQUEST_WANTED':
    case 'TPTR_PUSH_NEW_BOOKING_REQUEST_OPTIM':
    case 'TPTR_PUSH_PMT_AVAILABLE':
    case 'TPTR_PUSH_INVOICE_APPROVAL':
    case 'TPTR_PUSH_COUNTEROFFER_ACCEPTED':
    case 'TPTR_PUSH_COUNTEROFFER_REFUSED':
    case 'USER_PUSH_TEST':
      return true
    case 'TC_URGENT_DISPATCH_IS_SOON':
    case 'HPTL_PUSH_TPTR_ACCEPT_CHANGE_REQUEST':
    case 'HPTL_PUSH_TPTR_NOT_FOUND':
    case 'HPTL_PUSH_MISSION_TO_DISPATCH':
    case 'HPTL_PUSH_TPTR_ACCEPT':
    case 'HPTL_PUSH_TPTR_ACCEPT_EDIT':
    case 'HPTL_PUSH_TPTR_FLAG':
      return false
  }

  assertUnreachable(value)
}

const SoundOffButton = styled(Button).attrs({small: true, variant: 'third'})``

const SoundOnButton = styled(Button).attrs({small: true, variant: 'third'})`
  --bg-color: ${palette.amblea.tea[600]};
  --fg-color: ${palette.amblea.white};
  --bg-color-hover: ${palette.amblea.tea[700]};
`

export const SoundButton = () => {
  const product = useProduct()
  const {isMuted, setIsMuted} = React.useContext(NotificationAlertSoundContext)
  const [canRender, setCanRender] = React.useState(false)

  const toggleMute = React.useCallback(() => {
    if (isMuted) {
      setIsMuted(false)
      toastSuccess('Notifications sonores activées !')
    } else {
      setIsMuted(true)
      toastSuccess('Notifications sonores désactivées !')
    }
  }, [isMuted, setIsMuted])

  React.useEffect(() => {
    // ctrl + m
    const handleKeyUp = (e: KeyboardEvent) => {
      {
        if (e.ctrlKey && e.which == 77) {
          toggleMute()
        }
      }
    }
    document.addEventListener('keyup', handleKeyUp)
    return () => document.removeEventListener('keyup', handleKeyUp)
  }, [toggleMute])

  React.useEffect(() => {
    const isSSR = typeof window === 'undefined'
    const isA4T = product === 'A4T'
    const isMobileApp = process.env.IS_MOBILE_BUILD
    const updateCanRender = async () => {
      setCanRender(!isMobileApp && !isSSR && isA4T && (await getDeviceState()) === 'GRANTED')
    }
    updateCanRender()
  }, [product])

  if (!canRender) {
    return null
  }
  return (
    <Tooltip
      content={isMuted ? 'Activer les notifications sonores (Ctrl+M)' : 'Désactiver les notifications sonores (Ctrl+M)'}
    >
      {isMuted ? (
        <SoundOffButton onClick={toggleMute}>
          <Button.Icon icon={<SoundOffIcon />} />
        </SoundOffButton>
      ) : (
        <SoundOnButton onClick={toggleMute}>
          <Button.Icon icon={<SoundOnIcon />} />
        </SoundOnButton>
      )}
    </Tooltip>
  )
}

export const NotificationAlertSoundContext = React.createContext<{
  isMuted: boolean
  setIsMuted: (value: boolean) => void
}>(null)

const MockedAudioProvider: React.FC = ({children}) => {
  return (
    <NotificationAlertSoundContext.Provider value={{isMuted: true, setIsMuted: () => {}}}>
      {children}
    </NotificationAlertSoundContext.Provider>
  )
}

const AudioProvider: React.FC = ({children}) => {
  const [isMuted, setIsMuted] = React.useState<boolean>(true)

  const [playNormal] = useSound('/static/sounds/normal.wav')
  const [playImportant] = useSound('/static/sounds/important.wav')
  const [playUrgent] = useSound('/static/sounds/urgent.wav')
  const [playDefault] = useSound('/static/sounds/notification-alert-sound.mp3')

  React.useEffect(() => {
    const play = (filename: string) => {
      switch (filename) {
        case 'normal.wav':
          playNormal()
          break
        case 'important.wav':
          playImportant()
          break
        case 'urgent.wav':
          playUrgent()
          break
        default:
          playDefault()
      }
    }

    const onMessage = (event: FixType) => {
      if (event.data?.msg?.type === 'SW_WEBPUSH_RECEIVED') {
        if (!isMuted && needSound(event.data.msg.payload.type)) {
          play(event.data.msg.payload.sound)
        }
      }
    }
    navigator.serviceWorker.addEventListener('message', onMessage)

    return () => navigator.serviceWorker.removeEventListener('message', onMessage)
  }, [isMuted, playDefault, playImportant, playNormal, playUrgent])

  return (
    <NotificationAlertSoundContext.Provider value={{isMuted, setIsMuted}}>
      {children}
    </NotificationAlertSoundContext.Provider>
  )
}

const isAudioAvailable = typeof window !== 'undefined' && 'serviceWorker' in navigator && !process.env.IS_MOBILE_BUILD

export const NotificationAlertSoundProvider = isAudioAvailable ? AudioProvider : MockedAudioProvider
