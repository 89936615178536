import {SecureStorage} from '@aparajita/capacitor-secure-storage'

/**  
    As per the documentation (https://auth0.com/docs/quickstart/native/ionic-react/01-login):

 To persist authentication after closing and reopening the application, 
 you may want to set cacheLocation to localstorage when configuring the SDK, 
 but please be aware of the risks of storing tokens in localstorage. 
 Also, localstorage should be treated as transient in Capacitor app as the data might be recovered unexpectedly in certain circumstances. 
 Please read the guidance on storage in the Capacitor docs.

 Additionally, the SDK has the ability to use a custom cache implementation to store tokens, 
 if you have a requirement to use a more secure and persistent storage mechanism.

 Note that we recommend against using Capacitor's Storage plugin to store tokens, 
 as this is backed by UserDefaults and SharedPreferences on iOS and Android respectively. 
 Data stored using these APIs is not encrypted, not secure, and could also be synced to the cloud.
*/

// ? because localstorage cannot be trusted (transient)
// ? and capacitor preferences is not secured
// ? we use capacitor-secure-storage-plugin to store tokens securely (https://github.com/martinkasa/capacitor-secure-storage-plugin)
export default {
  get: async (key: string) => {
    if (!key) {
      // ? we encounter "No key provided" error at login, see if it still the case when removing auth0-spa-js
      return undefined
    }
    return SecureStorage.get(key) as Promise<any>
  },

  set: (key: string, value: any) => SecureStorage.set(key, value),

  remove: (key: string) => {
    SecureStorage.remove(key)
  },

  allKeys: () => SecureStorage.keys(),
}
