import React from 'react'
import {toastSuccess, toastError, toastInfo} from '@ambler/andive-next'
import {assertUnreachable} from '@ambler/shared'
import type {
  ApolloError,
  DocumentNode,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationResult,
  TypedDocumentNode,
} from '@apollo/client'
import {useMutation as useMutation_} from '@apollo/client'
import Sentry from '../lib/sentry'

export const DEFAULT_ERR_MESSAGE = "Une erreur s'est produite. Réessayez ou contactez nous sur support@amblea.fr"

type toasLevels = 'info' | 'success'
const toast = (level: toasLevels, message: string) => {
  switch (level) {
    case 'info':
      return toastInfo(message)
    case 'success':
      return toastSuccess(message)
  }
  assertUnreachable(level)
}

type useMutationParams<TData, TVariables> = {
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>
  mutationOptions?: Omit<MutationHookOptions<TData, TVariables>, 'onCompleted' | 'onError'>
  success?: {
    message?: string | ((data: TData) => string)
    level?: 'info' | 'success'
    callback?: (data: TData) => Promise<void> | void
  }
  error?: {
    message?: string | ((error: ApolloError) => string)
    callback?: (error: ApolloError) => Promise<void> | void
  }
  final?: () => Promise<void> | void
}

type EnhancedMutationFunction<TData, TVariables> = (
  options?: Omit<MutationFunctionOptions<TData, TVariables>, 'onCompleted' | 'onError'> & {
    success?: {
      message?: string | ((data: TData) => string)
      level?: 'info' | 'success'
      callback?: (data: TData) => Promise<void> | void
    }
    error?: {
      message?: string | ((error: ApolloError) => string)
      callback?: (error: ApolloError) => Promise<void> | void
    }
    final?: () => Promise<void> | void
  },
) => Promise<{data?: TData | null | undefined} | null>

export const useMutation = <TData, TVariables>({
  mutation,
  mutationOptions = {},
  success,
  error,
  final,
}: useMutationParams<TData, TVariables>): [EnhancedMutationFunction<TData, TVariables>, MutationResult<TData>] => {
  const [executeMutation, ...rest] = useMutation_<TData, TVariables>(mutation, mutationOptions)
  const ref = React.useRef({
    executeMutation,
    success,
    error,
    final,
  })

  React.useEffect(() => {
    ref.current = {
      executeMutation,
      success,
      error,
      final,
    }
  })

  const enhancedMutation: EnhancedMutationFunction<TData, TVariables> = React.useCallback(
    async options =>
      new Promise(resolve => {
        ref.current.executeMutation({
          ...options,
          onCompleted: async data => {
            const success_ = options?.success ?? ref.current.success
            const final_ = options?.final ?? ref.current.final

            if (success_?.message) {
              const msg = typeof success_.message === 'function' ? success_.message(data) : success_.message
              toast(success_.level || 'success', msg)
            }

            try {
              await success_?.callback?.(data)
            } catch (err) {
              console.error(err)
              Sentry.captureException(err)
            } finally {
              final_?.()
              resolve({data})
            }
          },
          onError: async apolloError => {
            const error_ = options?.error ?? ref.current.error
            const final_ = options?.final ?? ref.current.final

            // ? passing an explicit null value to the error message will prevent the toast from being displayed
            // ? undefined will display the default error message
            if (error_?.message !== null) {
              const msg = typeof error_?.message === 'function' ? error_.message(apolloError) : error_?.message
              toastError(msg ?? DEFAULT_ERR_MESSAGE)
            }
            const errors = [...apolloError.graphQLErrors, ...apolloError.clientErrors, apolloError.networkError]
            errors.forEach(err => {
              console.error(err)
            })
            try {
              await error_?.callback?.(apolloError)
            } catch (err) {
              console.error(err)
              Sentry.captureException(err)
            } finally {
              final_?.()
              resolve(null)
            }
          },
        })
      }),
    [],
  )

  return [enhancedMutation, ...rest]
}
