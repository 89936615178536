import Sentry from './sentry'

const isClientSide = typeof window !== 'undefined' || process.env.IS_MOBILE_BUILD

// https://stackoverflow.com/a/16427747
export const isLocalStorageAvailable = () => {
  const test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

// * Expose local storage's methods with safety
export const safeLocalStorage: Storage = {
  getItem(key: string): string | null {
    if (isClientSide) {
      try {
        return localStorage.getItem(key)
      } catch (err) {
        Sentry.captureException(err)
      }
    }
    return null
  },
  setItem(key: string, value: string): void {
    if (isClientSide) {
      try {
        localStorage.setItem(key, value)
      } catch (err) {
        Sentry.captureException(err)
      }
    }
  },
  removeItem(key: string): void {
    if (isClientSide) {
      try {
        localStorage.removeItem(key)
      } catch (err) {
        Sentry.captureException(err)
      }
    }
  },
  clear(): void {
    if (isClientSide) {
      try {
        localStorage.clear()
      } catch (err) {
        Sentry.captureException(err)
      }
    }
  },
  key(index: number): string | null {
    if (isClientSide) {
      try {
        return localStorage.key(index)
      } catch (err) {
        Sentry.captureException(err)
      }
    }
    return null
  },
  get length() {
    if (isClientSide) {
      try {
        return localStorage.length
      } catch (err) {
        Sentry.captureException(err)
      }
    }
    return 0
  },
}
