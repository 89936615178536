import {useRouter} from 'next/router'

export const useProduct = () => {
  const router = useRouter()
  const product = (() => {
    if (router.pathname.startsWith('/es')) {
      return 'A4H'
    }

    if (router.pathname.startsWith('/ts')) {
      return 'A4T'
    }

    return undefined
  })()

  return product
}
