import styled from 'styled-components'
import {AppBar, FlatButton} from '@ambler/andive'
import Link from 'next/link'

import Image from 'next/image'
import {LogoutIcon, palette} from '@ambler/andive-next'
import {useMobile} from './stack/responsive'

import Loader from './loader'
import {useAuth} from './stack/auth'

const Login = () => {
  const isMobile = useMobile()
  const {isLoading, isAuthenticated, signIn, signOut} = useAuth()
  if (isLoading) {
    return <Loader inline={true} size={24} />
  }
  if (isAuthenticated) {
    return (
      <FlatButton
        onClick={() => signOut()}
        icon={<LogoutIcon color={palette.amblea.red[700]} />}
        label="Déconnexion"
        color={palette.amblea.red[700]}
      />
    )
  }
  return <a onClick={() => signIn()}>{isMobile ? 'Accès Pro' : 'Accès professionnels'}</a>
}

const AppBarContainer = styled.div`
  padding: 0 8px;
  height: 64px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  && > * {
    align-self: center;
  }
`

function LogoAppBar() {
  return (
    <>
      <AppBar height={78}>
        <AppBarContainer>
          <Link href="/">
            <a>
              <Image src="/static/img/horizontal_blue.png" height="26px" width="110px" />
            </a>
          </Link>
          <Login />
        </AppBarContainer>
      </AppBar>

      <div style={{height: 16}} />
    </>
  )
}

export default LogoAppBar
