import React from 'react'

const Context = React.createContext<Date>(undefined)

export const useDateNow = () => {
  const now = React.useContext(Context)

  if (!now && process.env.NODE_ENV !== 'production') {
    throw new Error('You must DateNowProvider to use this hook')
  }

  return now
}

export const DateNowProvider: React.FC = props => {
  const [now, setNow] = React.useState(new Date())

  React.useEffect(() => {
    const id = window.setInterval(() => {
      setNow(new Date())
    }, 1000)

    return () => clearInterval(id)
  }, [])

  return <Context.Provider value={now}>{props.children}</Context.Provider>
}
