import React from 'react'
import {getMessaging, getToken, isSupported as isSupported_, onMessage} from 'firebase/messaging'
import {FirebaseMessaging} from '@capacitor-firebase/messaging'
import type {DeviceState, DeviceType} from '@prisma/client'
import {Product} from '@prisma/client'
import browser from 'browser-detect'
import {Capacitor} from '@capacitor/core'
import {App} from '@capacitor/app'
import {Device} from '@capacitor/device'
import {LocalNotifications} from '@capacitor/local-notifications'
import Router from 'next/router'
import {isLocalStorageAvailable} from './local-storage'

import firebase, {firebaseConfig} from './firebase'
import {hideSplashScreen} from './mobile/splash-screen'
import Sentry from './sentry'

const updateRaven = async (messageId: string) => {
  const pushEndpoint = `${process.env.WEBPUSH_ENDPOINT}/click`
  try {
    await fetch(pushEndpoint, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({messageId}),
    })
  } catch (err) {
    console.error(err.message)
    // ? failing to update raven might be the result of a faulty internet connection and should not trigger sentry
  }
}

const isSupported = async () => {
  if (process.env.IS_MOBILE_BUILD) {
    const result = await FirebaseMessaging.isSupported()
    return result.isSupported
  }

  return await isSupported_()
}
const checkNotificationPermissions = async () => {
  if (process.env.IS_MOBILE_BUILD) {
    const result = await FirebaseMessaging.checkPermissions()
    return result.receive
  }

  return Notification.permission
}

export const useMobileNotifications = () => {
  React.useEffect(() => {
    if (!process.env.IS_MOBILE_BUILD) {
      return null
    }

    if (Capacitor.getPlatform() === 'android') {
      // ? on android 8 (sdk 26) and above, custom sounds are per channels, not global to the app
      FirebaseMessaging.createChannel({
        id: 'normal',
        name: "Notifications d'importance normale",
        sound: 'normal',
      })
      FirebaseMessaging.createChannel({
        id: 'important',
        name: 'Notifications importantes',
        sound: 'important',
      })
      FirebaseMessaging.createChannel({
        id: 'urgent',
        name: 'Notifications urgentes',
        sound: 'urgent',
      })
    }

    // ? this is triggered when the app is in foreground
    // ? on android the notification is not displayed natively.
    // https://stackoverflow.com/a/75990651
    FirebaseMessaging.addListener('notificationReceived', event => {
      const {notification} = event
      if (Capacitor.getPlatform() === 'android') {
        FirebaseMessaging.getDeliveredNotifications().then(deliveredNotifications => {
          FirebaseMessaging.removeDeliveredNotifications(deliveredNotifications).then(() => {
            const data: Record<string, any> = notification.data
            LocalNotifications.schedule({
              notifications: [
                {
                  id: 1,
                  title: notification.title,
                  body: notification.body,
                  schedule: {at: new Date(Date.now() + 1000 * 1)},
                  extra: {...data, fcmId: notification.id},
                  channelId: data['android_channel_id'], // android 26 +
                },
              ],
            })
          })
        })
      }
      // IOS: no-op (foreground handle the same way as background)
    })
    // ? this is triggered whenever a notification is clicked:
    FirebaseMessaging.addListener('notificationActionPerformed', async event => {
      const {data, id}: {data?: Record<string, any>; id?: string} = event.notification
      // ? android and ios native firebase plugins do not use the same id
      const notificationId = Capacitor.getPlatform() === 'ios' ? data['gcm.message_id'] : id
      await updateRaven(notificationId)
      Router.push(data.route)
      hideSplashScreen()
    })

    // ? Android local notification click
    LocalNotifications.addListener('localNotificationActionPerformed', async event => {
      const {route, fcmId} = event.notification.extra
      await updateRaven(fcmId)
      Router.push(route)
      hideSplashScreen()
    })

    return () => {
      FirebaseMessaging.removeAllListeners()
      LocalNotifications.removeAllListeners()
    }
  }, [])
}

const registerAndGetToken = async () => {
  if (process.env.IS_MOBILE_BUILD) {
    const result = await FirebaseMessaging.getToken({
      vapidKey: firebaseConfig.vapidKey,
    })
    return result.token
  }

  // ? background notifications will only focus tab with the same host if any is already open. We force redirection here
  // https://github.com/firebase/firebase-js-sdk/issues/3922#issuecomment-1197002484
  navigator.serviceWorker.onmessage = async event => {
    if (event.data.messageType === 'notification-clicked') {
      const messageId = event.data.fcmMessageId
      await updateRaven(messageId)
      const currentPage = window.location.href.replace(window.location.origin, '')
      if (currentPage === event.data.notification.click_action) {
        return
      }
      window.location.href = event.data.notification.click_action
    }
  }

  const messaging = getMessaging(firebase)

  const serviceWorkerRegistration = await navigator.serviceWorker.register('/sw.js')

  await navigator.serviceWorker.ready

  const result = await getToken(messaging, {
    vapidKey: firebaseConfig.vapidKey,
    serviceWorkerRegistration,
  })

  return result
}

function randomUUID() {
  // crypto.randomUUID() not supported by firefox < 95
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
    // @ts-ignore https://stackoverflow.com/a/2117523
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  )
}
const getDeviceId = async () => {
  if (process.env.IS_MOBILE_BUILD) {
    const {identifier} = await Device.getId()
    return identifier
  }

  if (!isLocalStorageAvailable()) {
    return 'no_storage_device'
  }

  let machineId = localStorage.getItem('firebaseDeviceId')
  if (!machineId) {
    machineId = randomUUID()
    localStorage.setItem('firebaseDeviceId', machineId)
  }
  return machineId
}
const getDeviceInfo = async () => {
  if (process.env.IS_MOBILE_BUILD) {
    const result = await Device.getInfo()
    return {
      os: `${result.operatingSystem} ${result.osVersion}`,
      hardware: result.model,
    }
  }

  const result = browser()
  return {
    browserName: result.name,
    browserVersion: result.version,
    os: result.os,
  }
}

export const requestNotificationPermissions = async () => {
  if (process.env.IS_MOBILE_BUILD) {
    const result = await FirebaseMessaging.requestPermissions()
    return result.receive
  }

  return Notification.requestPermission()
}

export const getDeviceState = async (): Promise<DeviceState> => {
  if (!(await isSupported())) {
    return 'UNSUPPORTED'
  }
  const permission = await checkNotificationPermissions()

  if (permission === 'denied') {
    return 'DENIED'
  }
  if (permission === 'granted') {
    return 'GRANTED'
  }

  return 'INITIAL'
}

export const getUpdateDevicePayload = async () => {
  const type = (() => {
    if (process.env.IS_MOBILE_BUILD) {
      return Capacitor.getPlatform().toUpperCase()
    }
    return 'WEB'
  })()

  const appBundleVersion = await (async () => {
    if (process.env.IS_MOBILE_BUILD) {
      const info = await App.getInfo()
      return info.version
    }

    return null
  })()

  const deviceState = await getDeviceState()

  let fcmToken = null

  // https://ambler.sentry.io/issues/5038353823/events/357172bb07684effa341ada0f651ca43/?environment=prod&project=1286806&statsPeriod=14d
  if (deviceState === 'GRANTED') {
    try {
      fcmToken = await registerAndGetToken()
    } catch (err) {
      // ? For now we don't want to trigger a sentry report popup. Therefore
      // ? we keep the info accessible if there is a subsequent bug in the app
      // ? to know that the sw was not registered.
      Sentry.addBreadcrumb(err)
    }
  }

  return {
    product: Product.APP,
    type: type as DeviceType,
    appVersion: process.env.version,
    appBundleVersion,
    deviceId: await getDeviceId(),
    state: deviceState,
    ...(await getDeviceInfo()),
    fcmToken,
  }
}

// ? background notifications are already handled by default
// ? this onMessage listener will only be triggered if the app has focus
// https://firebase.google.com/docs/cloud-messaging/js/receive#web-namespaced-api_3
export const useForegroundNotifications = () => {
  const [localIsSupported, setIsSupported] = React.useState(false)

  React.useEffect(() => {
    const asyncIsSupp = async () => {
      setIsSupported(await isSupported())
    }
    asyncIsSupp()
  }, [])

  React.useEffect(() => {
    if (process.env.IS_MOBILE_BUILD || !localIsSupported) {
      return null
    }

    const messaging = getMessaging(firebase)
    const unlisten = onMessage(messaging, async payload => {
      try {
        const {notification, fcmOptions, messageId} = payload
        // ? tag is used to prevent multiple notifications for the same message
        // ? as this event listener can be register on more than one tab.
        const notif = new Notification(notification.title, {...notification, tag: messageId})
        notif.onclick = async () => {
          await updateRaven(messageId)
          window.open(fcmOptions.link, '_blank')
        }
      } catch (_err) {
        // ! mobile chrome does not support new Notification() calls
        // https://issues.chromium.org/issues/40415865
        // as this method is only intended to display foreground notifications, this is a no op
        // mobile users should use the native app for full notification support
      }
    })

    return unlisten
  }, [localIsSupported])
}
