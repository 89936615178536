import React from 'react'

export const useAuth_cypress = () => {
  const [user, setUser] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)
  React.useEffect(() => {
    const user_ = JSON.parse(localStorage.getItem('auth0Cypress_user'))
    setUser(user_)
    setIsLoading(false)
  }, [])

  return {
    user,
    isAuthenticated: Boolean(user),
    isLoading,
    loginWithRedirect: () => {},
    logout: () => {},
    buildAuthorizeUrl: () => {},
    buildLogoutUrl: () => {},
  }
}
export class Auth0Client_cypress {
  constructor(private _options: any) {}

  public async getTokenSilently(_options: any = {}): Promise<string> {
    return ''
  }
  public async getIdTokenClaims(_options: any = {}): Promise<any> {
    const token = localStorage.getItem('auth0Cypress_token')
    return {
      __raw: token,
    }
  }
}
